import '../styles/news-post.scss'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import SEO from '../components/SEO'
import Layout from './Layout'

export const data = graphql`
  query ($title: String!) {
    datoCmsNewsPost(title: { eq: $title }) {
      title
      ledeParagraph
      ledeParagraphNode {
        childMarkdownRemark {
          html
        }
      }
      article {
        ... on DatoCmsHeadline {
          id
          model {
            apiKey
          }
          headline
        }
        ... on DatoCmsTextBlock {
          id
          model {
            apiKey
          }
          textNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsImageBlock {
          id
          model {
            apiKey
          }
          image {
            fluid(maxWidth: 1080) {
              ...GatsbyDatoCmsFluid
            }
          }
          captionNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

const NewsPostLayout = ({ data }) => {
  const dato = data.datoCmsNewsPost
  const getHtml = node => ({
    __html: node.childMarkdownRemark.html,
  })

  return (
    <Layout id="news-post">
      <SEO title={dato.title} description={dato.ledeParagraph} />
      <article id="post-content">
        <section id="heading">
          <h1>{dato.title}</h1>
          <div
            className="lede"
            dangerouslySetInnerHTML={getHtml(dato.ledeParagraphNode)}
          />
        </section>
        <section id="post-body">
          {dato.article.map(block => (
            <React.Fragment key={block.id}>
              {block.model.apiKey === 'headline' && (
                <h3>{block.headline}</h3>
              )}
              {block.model.apiKey === 'text_block' && (
                <div
                  dangerouslySetInnerHTML={getHtml(block.textNode)}
                />
              )}
              {block.model.apiKey === 'image_block' && (
                <div className="image-block">
                  <Img fluid={block.image.fluid} />
                  {block.captionNode && (
                    <div
                      className="caption"
                      dangerouslySetInnerHTML={getHtml(
                        block.captionNode
                      )}
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </section>
      </article>
    </Layout>
  )
}

export default NewsPostLayout
